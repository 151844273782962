.flowchart {
  margin-bottom: 10rem;

  h2 {
    color: $c-main;
    text-align: center;
    max-width: 35rem;
    margin: 2rem auto 4rem auto;
  }
  &__title {
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
    border-bottom: 1px solid #f0f0f0;
    padding: .5rem;
    margin-top: -3rem;
    color: $c-main;
  }
  &__startlink {
    display: block;
    width: 12rem;
    margin: 0 auto;
    background-color: #eee;
    text-align: center;
    line-height: 2;
    color: $c-main;
    color: rgba($c-main, .6);

    &:hover {
      color: $c-main;
      text-decoration: none;
      background-color: #ddd;
    }

    &::before {
      content: "← ";
    }
  }
  &__buttonwrap {
    margin: 4rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
  &__button {
    display: block;
    background-color: $c-main;
    color: #fff;
    font-size: 1.25rem;
    padding: .75rem 2rem;
    margin: 0 .5rem;
    margin-bottom: .5rem;
    text-align: center;
    line-height: 1.2;
    box-shadow: 0 5px 10px -4px rgba($c-main, .3);

    &--pink {
      background-color: $c-highlight;
    }
    &--grey {
      background-color: #aaa;
    }
  }
  &__boxwrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__box {
    background-color: #fafafa;
    border: 1px solid #eee;
    width: 49%;
    padding: 1rem;
    box-sizing: border-box;
    margin-bottom: 2%;

    @include bp-s() {
      width: 100%;
    }

    h3 {
      font-size: 1.2rem;
      margin-top: 0;
      margin-bottom: .5rem;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}