.team {
  margin-bottom: 2rem;
}
.team__title {
  font-size: 1rem;
  color: $c-main;
  margin-top: 1rem;
  margin-bottom: 0;
  line-height: 1.4;
}
.team__function {
  line-height: 1.4;
}
.team__image {
  display: block;
  width: 100%;
  height: auto;
}
.team-wrap {
  margin-top: 4rem;
}
.team__email {
  line-height: 1.4;
}