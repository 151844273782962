.supporter {
  margin-bottom: 2rem;
}
.supporter__title {
  font-size: .9rem;
  color: $c-main;
  margin: 0;
  margin-top: .5rem;
  line-height: 1.4;
  font-weight: normal;
}
.supporter__logo {
  line-height: 1.4;
}
.supporter__logowrap {
  width: 100%;
  padding-bottom: 60%;
  position: relative;
  border: 1px solid rgba($c-main, .3);
}
.supporter__logo {
  display: block;
  position: absolute;
  top: 15%;
  left: 15%;
  width: 70%;
  height: 70%;
  object-fit: contain;
  object-position: center;
}