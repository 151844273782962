.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  box-sizing: border-box;
  padding: 0 1rem;
  background: rgba(#fff, .9);
  z-index: 6;
  display: flex;
  backdrop-filter: blur(7px);

  .list-wrap {
    display: flex;
    width: 100%;
    max-width: 940px;
    margin: 0 auto;
  }
  ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
  }
  ul.lang {
    width: auto;
    margin-left: 2rem;

    li {
      margin-left: .5rem;

      a {
        color: $c-main;
      }
      &.active a {
        color: $c-highlight;
      }
    }
  }
  li {
    display: block;
    line-height: 3rem;
    color: $c-main;
    font-family: $f-highlight;
    font-size: .8rem;
    letter-spacing: .3em;
    text-transform: uppercase;
    position: relative;
  
    @include bp-from-s() {
      &.js-subnav > ul {
        display: none;
        position: absolute;
        left: -.5rem;
        top: 2.5rem;
        width: auto;
        background: $c-highlight;
        flex-direction: column;
        box-shadow: 0 4px 20px -4px rgba(#fff, .51);
  
        &.visible {
          display: block;
        }
  
        li {
          color: #fff;
          display: block;
          line-height: 2.5;
  
          a {
            display: block;
            padding: 0 .5rem;
  
            &:hover {
              color: #fff;
              background-color: rgba(#fff, .1);
            }
          }
        }
      }
    }
  }
  a:link, a:visited {
    color: inherit;
    font-family: $f-highlight;
    font-size: .8rem;
    letter-spacing: .3em;
    text-transform: uppercase;
    transition: .1s color;
  }
  a:hover {
    color: $c-highlight;
    text-decoration: none;
  }

  &__lang {
    a:link, a:visited {
      color: $c-highlight;
    }
  }
}

@include bp-s() {
  .main-nav {
    height: 100%;
    background-color: $c-highlight;
    padding: 4rem;
    transform: translateX(100%);
    transition: .5s $easeOutCirc;

    ul {
      display: block;
      position: relative;
      padding-top: 5rem;
    }
    li {
      line-height: 1;
      margin-bottom: 2rem;

      &.js-subnav > ul {
        padding-top: 0;
      }
    }
    .subnav-link {
      display: none;
    }
    a:link, a:visited {
      font-size: 1.2rem;
      color: #fff;
    }
    li.main-nav__lang {
      position: absolute;
      top: 0;
      left: 0;
    }

    &.visible {
      transform: translateX(0);
    }
    ul.lang {
      position: fixed;
      top: 0;
      left: 4rem;
      margin-left: 0;

      li {
        display: inline-block;
        margin-left: 0;
      }
      li a, li.active a {
        color: #fff;
      }
    }
  }
}

.mobile-header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  box-sizing: border-box;
  z-index: 5;
  background: rgba(#fff, 0);
  box-shadow: 0 0 20px 0 rgba($c-main, .0);
  transition: .5s background-color, .5s box-shadow;

  h1 {
    font-size: 1.2rem;
    color: inherit;
    margin: 0;
    line-height: 3rem;
    opacity: 0;
    transition: .5s opacity;
  }

  @include bp-s() {
    display: block;
  }

  .is-scrolled & {
    background: #fff;
    box-shadow: 0 0 20px 0 rgba($c-main, .15);

    h1 {
      opacity: 1;
    }
  }
}
.mobile-btn {
  position: absolute;
  right: 1rem;
  top: .5rem;
  display: none;
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeTE9IjEiIHgyPSIxNiIgeTI9IjEiIHN0cm9rZT0iIzI5MjY1QiIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxsaW5lIHkxPSI2IiB4Mj0iMTYiIHkyPSI2IiBzdHJva2U9IiMyOTI2NUIiIHN0cm9rZS13aWR0aD0iMiIvPgo8bGluZSB5MT0iMTEiIHgyPSIxNiIgeTI9IjExIiBzdHJva2U9IiMyOTI2NUIiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K");
  background-size: 16px auto;
  background-position: 100% 50%;
  background-repeat: no-repeat;

  span {
    display: none;
  }

  &--close {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjEuMjkyODkiIHkxPSIxNC4yOTI5IiB4Mj0iMTQuMjkyOSIgeTI9IjEuMjkyODkiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8bGluZSB4MT0iMS43MDcxMSIgeTE9IjEuMjkyODkiIHgyPSIxNC43MDcxIiB5Mj0iMTQuMjkyOSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=");
  }

  @include bp-s() {
    display: block;
  }
}