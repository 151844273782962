.partner {
  margin-bottom: 4rem;
}
.partner__title {
  font-size: 1rem;
  color: inherit;
  font-weight: normal;
}
.partner__logo {
  display: block;
  margin: -1rem 0 2rem 0;
  width: 80%;
  max-width: 250px;

  .partner--main & {
    max-width: 500px;
  }
}