.footer {
  margin-top: 10rem;
  padding: 3rem 1rem;
  background-color: $c-main;
  color: #fff;
  box-sizing: border-box;

  @include bp-s() {
    margin-top: 6rem;
  }
}
.footer__row {
  margin: 2rem auto;
  max-width: 940px;
  display: flex;

  @include bp-s() {
    flex-wrap: wrap;
  }
  // @include bp-l() {
  //   max-width: 1200px;
  // }

  &--copyright {
    margin: -1rem auto 2rem auto;

    @include bp-s() {
      margin: -2rem auto 0 auto;
    }
  }
}
.footer__col {
  padding-right: 2rem;
  flex-basis: 100%;
}
.footer__logo {
  width: 13rem;
  height: auto;
  display: block;
  transform: translateX(-12%);

  @include bp-s() {
    width: 8rem;
    height: 5.2rem;
  }
}

.footer a:link, .footer a:visited {
  color: inherit;
}

.social-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: 100%;
  margin-right: 1rem;
  transition: .2s background-color;

  span {
    display: none;
  }
  &--twitter {
    background: url('../assets/social-twitter.svg');
  }
  &--fb {
    background: url('../assets/social-fb.svg');
  }
  &:hover {
    background-color: $c-highlight;
  }
}

.footer__copyright {
  display: block;
  font-family: $f-highlight;
  margin-top: 3rem;
}