.media {
  margin-bottom: 4rem;
}
.media__image {
  display: block;
  width: 100%;
  height: auto;
}
.media__meta {
  display: block;
  font-family: $f-highlight;
  font-size: .9rem;
  margin: 1rem 0;
}
.media__title {
  margin: .75rem 0 .5rem 0;
}
.media__link {
  display: inline-block;
  margin-top: .5rem;
}
.media__teaser {
  margin-top: .5rem;
}

.container--moremedia {
  margin-top: -2rem !important;
}