@mixin bp-s() {
  @media screen and (max-width: 639px) {
    @content;
  }
}
@mixin bp-from-s() {
  @media screen and (min-width: 640px) {
    @content;
  }
}

@mixin bp-m() {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin bp-l() {
  @media screen and (min-width: 1400px) {
    @content;
  }
}

@mixin print() {
  @media print {
    @content;
  }
}

$easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);