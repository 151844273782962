@import './reset';
@import './helpers';
@import './fonts';

$c-main: #29235C;
$c-highlight: #E61D73;
$f-main: 'apercu-regular', Helvetica, Arial, sans-serif;
$f-highlight: 'apercu-mono-regular';

@import './nav';
@import './hero';
@import './media';
@import './team';
@import './partner';
@import './supporter';
@import './flowchart';
@import './footer';

html {
  font-size: 18px;

  @include bp-s() {
    font-size: 14px;
  }
}
body {
  background-color: #fff;
  font-family: $f-main;
  color: $c-main;
  padding-top: 3rem;
}
p {
  line-height: 1.4;
  margin: 1rem 0;

  .lead &, &.lead {
    font-size: 1.2rem;
  }
  strong {
    font-weight: bold;
  }
}
h1, h2, h3, h4 {
  font-family: $f-main;
  font-weight: bold;
  color: $c-highlight;
  line-height: 1.3;
  margin: 2rem 0;
}
h1, h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.4rem;
}
h4 {
  font-size: 1rem;
  margin: 1.5rem 0 1rem 0;
  color: $c-main;
}
h1 + p, h2 + p {
  margin-top: -1rem;
}
a {
  color: $c-highlight;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a.black-link, a.grey-link, a.grey-light-link {
  display: inline-block;
  background-color: #000;
  color: #fff;
  padding: .5rem 1rem;
  font-family: 'apercu-mono-regular';
  text-transform: lowercase;
}
a.grey-link {
  background-color: #ccc;
  color: #000;
}
a.grey-light-link {
  background-color: #eee;
  color: #000;
}
em {
  font-style: italic;
}
.container ul {

  li {
    line-height: 1.4;
    margin: .25rem 0;
    padding-left: 1.5rem;
    position: relative;

    &::before {
      content: "—";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.container {
  max-width: 940px;
  margin: 5rem auto;
  padding: 0 1rem;
  position: relative;

  &--center {
    text-align: center;
  }
  &--spacer {
    margin: 8rem auto;
    text-align: center;

    @include bp-s() {
      margin: 6rem auto;
    }
  }

  &#participate {
    margin: 8rem auto;
  }

  // @include bp-l() {
  //   max-width: 1200px;
  // }
}
.container-highlight {
  background-color: $c-highlight;
  max-width: calc(940px + 6rem);
  margin: 3rem auto;
  padding: 0 1rem;
  color: #fff;

  .container {
    padding: 1rem 0 3rem 0;
    margin: 0 auto;

    @include bp-s() {
      padding-bottom: 1rem;
    }
  }
  h2 {
    color: #fff;
  }

  // @include bp-l() {
  //   max-width: calc(1200px + 6rem);
  // }
}
.row {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 2rem);
  margin-left: -1rem;

  &--flex {
    flex-wrap: nowrap;

    @include bp-m() {
      flex-wrap: wrap;
    }
  }
  &--hidden {
    display: none;
  }
}
.col {
  box-sizing: border-box;
  padding: 0 1rem;

  &--flex {
    flex-basis: 100%;
    flex-grow: 0;
  }
  &--3 {
    width: 33.3%;
    @include bp-s() {
      width: 100%;
    }
  }
  &--2 {
    width: 50%;
    @include bp-s() {
      width: 100%;
    }
  }
  &--4 {
    width: 25%;
    @include bp-s() {
      width: 50%;
    }
  }
}


.btn {
  background-color: transparent;
  color: $c-main;
  border: 2px solid $c-main;
  display: inline-block;
  text-decoration: none;
  padding: 1.2rem 1.8rem;
  font-weight: bold;
  letter-spacing: .2em;
  text-transform: uppercase;
  box-shadow: 0 8px 0 0 rgba($c-main, 0);
  transition: .1s background-color, .1s box-shadow;

  &:link, &:visited {
    color: inherit;
  }
  &:hover {
    text-decoration: none;
    background-color: rgba($c-main, .05);
    box-shadow: 0 4px 10px 0 rgba($c-main, .15);
  }
  &--cta {
    background-color: $c-main;
    padding: 1.2rem 1.8rem;
    border: none;

    &:link, &:visited {
      color: #fff;
    }
    &:hover {
      background-color: rgba($c-main, .9);
      background-color: $c-highlight;
      // box-shadow: 0 4px 15px 0 rgba($c-main, .3);
    }
  }
}

.initiator-logo {
  display: block;
  width: 80%;
  max-width: 300px;
  margin: 4rem 0 2rem 0;
}

.spacer-dog {
  width: 11rem;
  height: auto;

  @include bp-s() {
    width: 8rem;
  }
}

.home-link {
  display: block;
  width: 8rem;
  transform: translateX(-12%);
  margin-top: -3rem;
  margin-bottom: 6rem;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
  &:hover {
    img {
      opacity: .8;
    }
  }
  &--flowchart {
    width: 6rem;
    margin-top: -4rem;
    margin-bottom: 4rem;
  }
  @include bp-s() {
    margin-top: -7rem;
    transform: none;
  }
}

.cta-text {
  max-width: 37rem;
  margin: 2rem auto; 
  
  p {
    line-height: 1.5;
  }
  &-sec {
    text-align: left;
    max-width: 37rem;
    margin: 4rem auto 2rem auto;

    h4 {
      font-size: 1.2rem;
      color: $c-highlight;
    }
  }
}
.cta-image {
  width: 10rem;
  height: auto;
}