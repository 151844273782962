@font-face {
  font-family: 'apercu-regular';
  src: url('../fonts/apercu-regular.eot');
  src: url('../fonts/apercu-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/apercu-regular.woff2') format('woff2'),
    url('../fonts/apercu-regular.woff') format('woff'),
    url('../fonts/apercu-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'apercu-mono-regular';
  src: url('../fonts/apercu-mono-regular.eot');
  src: url('../fonts/apercu-mono-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/apercu-mono-regular.woff2') format('woff2'),
    url('../fonts/apercu-mono-regular.woff') format('woff'),
    url('../fonts/apercu-mono-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'apercu-regular';
  src: url('../fonts/apercu-bold.eot');
  src: url('../fonts/apercu-bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/apercu-bold.woff2') format('woff2'),
    url('../fonts/apercu-bold.woff') format('woff'),
    url('../fonts/apercu-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
