.hero {
  height: 32rem;
  width: 100%;
  position: relative;
  overflow: hidden;

  .container {
    margin: 0 auto;
  }

  @include bp-s() {
    height: 22rem;
    margin-bottom: -4rem;
    margin-top: -2rem;
  }
  // @include bp-l() {
  //   height: 32rem;
  // }
}
.hero__logo {
  width: 13rem;
  position: absolute;
  top: 2rem;
  left: 1rem;
  transform: translateX(-12%);
  z-index: 1;

  // @include bp-m() {
  //   width: 9rem;
  // }
  @include bp-s() {
    width: 8rem;
    top: 0;
    transform: none;
  }
}

.lottie {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 1100px;
  height: 500px;
  transform: translateX(-50%);

  @include bp-m() {
    width: 880px;
    height: 400px;
    transform: translateX(-50%);
  }
  @include bp-s() {
    width: 564px;
    height: 300px;
    transform: translateX(-60%);
  }
  // @include bp-l() {
  //   width: 1320px;
  //   height: 600px;
  //   // transform: translateX(-60%);
  // }
}